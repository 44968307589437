import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { GalleryComponent, GalleryItem } from '@daelmaak/ngx-gallery';

@Component({
  selector: 'app-product-info-dialog',
  imports: [CommonModule, MatDialogModule, GalleryComponent],
  templateUrl: './product-info-dialog.component.html',
  styleUrl: './product-info-dialog.component.scss',
})
export class ProductInfoDialogComponent {
  images: GalleryItem[] = [
    { src: 'assets/Orbis/img_1.jpg', thumbSrc: 'assets/Orbis/img_1.jpg' },
    { src: 'assets/Orbis/img_2.jpg', thumbSrc: 'assets/Orbis/img_2.jpg' },
    { src: 'assets/Orbis/img_3.jpg', thumbSrc: 'assets/Orbis/img_3.jpg' },
    { src: 'assets/Orbis/img_4.jpg', thumbSrc: 'assets/Orbis/img_4.jpg' },
    { src: 'assets/Orbis/img_5.jpg', thumbSrc: 'assets/Orbis/img_5.jpg' },
    { src: 'assets/Orbis/img_6.jpg', thumbSrc: 'assets/Orbis/img_6.jpg' },
    { src: 'assets/Orbis/img_7.jpg', thumbSrc: 'assets/Orbis/img_7.jpg' },
    { src: 'assets/Orbis/img_8.jpg', thumbSrc: 'assets/Orbis/img_8.jpg' },
    { src: 'assets/Orbis/img_9.jpg', thumbSrc: 'assets/Orbis/img_9.jpg' },
  ];
}
