<header class="flex flex-col justify-center w-full max-w-7xl mx-auto text-3xl bg-white shadow-sm">
  <nav class="hidden sm:flex flex-row justify-between p-4">
    <div class="flex flex-row items-center">
      <a href="/">
        <img src="assets/navigandi-logo.png" alt="Logo da empresa" class="w-20 h-20" />
      </a>
    </div>
    <div class="flex flex-row items-center">
      <ul class="flex flex-row items-center">
        <li class="mx-2 text-blue-950 text-xl hover:text-blue-800"><a href="#home">Início</a></li>
        <li class="mx-2 text-blue-950 text-xl hover:text-blue-800"><a href="#about">Sobre</a></li>
        <li class="mx-2 text-blue-950 text-xl hover:text-blue-800">
          <a href="#services">Serviços</a>
        </li>
        <li class="mx-2 text-blue-950 text-xl hover:text-blue-800">
          <a href="#products">Produtos</a>
        </li>
        <li class="mx-2 text-blue-950 text-xl hover:text-blue-800">
          <a href="/auth/signin" title="Entrar">Entrar</a>
        </li>
        <li
          class="mx-2 text-white text-xl bg-yellow-400 hover:bg-yellow-500 px-4 py-2 rounded-full"
        >
          <a href="#contact">Contato</a>
        </li>
      </ul>
    </div>
  </nav>

  <nav
    class="sm:hidden flex flex-col justify-between items-center border-b bg-white border-blue-900"
  >
    <div class="flex flex-row-reverse justify-between w-full items-center p-4">
      <button mat-icon-button aria-label="Menu icon" class="text-blue-950" (click)="toggleMenu()">
        <mat-icon *ngIf="isCollapsed">close</mat-icon><mat-icon *ngIf="!isCollapsed">menu</mat-icon>
      </button>
      <div class="flex flex-row items-center justify-start ml-2 w-full">
        <a href="/">
          <img src="assets/navigandi-logo.png" alt="Logo da empresa" class="w-14 h-14" />
        </a>
      </div>
    </div>
    <div *ngIf="isCollapsed" class="flex flex-row items-center w-full bg-slate-100">
      <ul class="grid grid-cols-1 gap-2 justify-start items-start w-full p-4">
        <li (click)="toggleMenu()" class="flex w-full">
          <a href="#home" class="text-slate-950 text-xl p-2 w-full bg-white border border-slate-300"
            >Início</a
          >
        </li>
        <li (click)="toggleMenu()" class="flex w-full">
          <a
            href="#about"
            class="text-slate-950 text-xl p-2 w-full bg-white border border-slate-300"
            >Sobre</a
          >
        </li>
        <li (click)="toggleMenu()" class="flex w-full">
          <a
            href="#services"
            class="text-slate-950 text-xl p-2 w-full bg-white border border-slate-300"
            >Serviços</a
          >
        </li>
        <li (click)="toggleMenu()" class="flex w-full">
          <a
            href="#products"
            class="text-slate-950 text-xl p-2 w-full bg-white border border-slate-300"
            >Produtos</a
          >
        </li>
        <li (click)="toggleMenu()" class="flex w-full">
          <a
            href="/auth/signin"
            class="text-slate-950 text-xl p-2 w-full bg-white border border-slate-300"
            title="Entrar"
            >Entrar</a
          >
        </li>
        <li (click)="toggleMenu()" class="flex w-full">
          <a
            href="#contact"
            class="text-slate-950 text-xl p-2 w-full bg-white border border-slate-300"
            >Contato</a
          >
        </li>
      </ul>
    </div>
  </nav>
</header>
<section
  class="w-full bg-blue-950 py-6 sm:py-32 bg-[url('/assets/ship.png')] bg-contain bg-right bg-no-repeat"
  id="home"
>
  <div
    class="flex flex-col sm:flex-row justify-start items-center w-full max-w-7xl mx-auto text-3xl p-4 sm:pt-4 h-full"
  >
    <div class="flex flex-col justify-center items-start w-full sm:w-3/4">
      <h1 class="text-3xl font-bold sm:text-6xl w-full text-white text-left">
        Inovação em sistemas de navegação
      </h1>
      <p class="text-lg sm:text-lg text-slate-300 text-left mt-8">
        Comprometimento com a excelência em desenvolvimento de equipamentos e sistemas para
        embarcações no Brasil e no Mundo. Tecnologia e precisão a serviço da segurança da navegação.
      </p>
      <div class="flex flex-col sm:flex-row justify-between items-center w-full h-full mt-8">
        <div class="flex flex-col items-start w-full sm:h-40 sm:pr-2">
          <h2 class="text-sm sm:text-xl text-white text-left">
            <span class="border-b-2 border-yellow-300 pb-1">Tecnologia</span> Nacional
          </h2>
          <p class="text-sm sm:text-base text-slate-300 text-left mt-2 w-full">
            Desenvolvida e testada em diversos portos brasileiros
          </p>
        </div>
        <div class="flex flex-col items-start w-full sm:h-40 sm:pr-2">
          <h2 class="text-sm sm:text-xl text-white text-left">
            <span class="border-b-2 border-yellow-300 pb-1">Soluções</span> Customizadas
          </h2>
          <p class="text-sm sm:text-base text-slate-300 text-left mt-2 w-full">
            Atendendo necessidades locais específicas
          </p>
        </div>
        <div class="flex flex-col items-start w-full sm:h-40 sm:pr-2">
          <h2 class="text-sm sm:text-xl text-white text-left">
            <span class="border-b-2 border-yellow-300 pb-1">Parcerias</span> Estratégicas
          </h2>
          <p class="text-sm sm:text-base text-slate-300 text-left mt-2 w-full">
            Cooperação técnica com universidades, centros de pesquisa e entidades setoriais
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="w-full bg-white py-6 sm:py-24" id="about">
  <div
    class="flex flex-col sm:flex-row justify-center items-center w-full sm:max-w-7xl mx-auto text-3xl p-4 sm:pt-4 h-full"
  >
    <div class="flex flex-col justify-center items-center w-full sm:pr-4">
      <h2 class="text-2xl sm:text-5xl font-bold w-full text-slate-700 text-left">
        Sobre a Navigandi
      </h2>
      <p class="text-lg sm:text-lg text-slate-500 text-left mt-4 sm:mt-8">
        Fundada em 2019 por pesquisadores oriundos do Tanque de Provas Numérico (TPN) da
        Universidade de São Paulo, a Navigandi é uma Startup de base tecnológica focada no
        desenvolvimento de software, sistemas e produtos de posicionamento e navegação para o uso em
        operações portuárias e offshore. Nossa jornada é pautada pela busca constante de inovação,
        compromisso com a qualidade e uma profunda conexão com as necessidades de nossos parceiros.
      </p>
    </div>

    <div class="flex flex-row justify-center items-center w-full mt-4 sm:mt-0">
      <img
        src="assets/sobre.jpg"
        alt="Logo da empresa"
        class="w-full h-auto shadow-sm rounded-lg"
      />
    </div>
  </div>
</section>

<section class="w-full bg-slate-50 py-6 sm:py-24" id="services">
  <div
    class="flex flex-col justify-center items-center w-full sm:max-w-7xl mx-auto text-3xl p-4 sm:pt-4 h-full"
  >
    <h2 class="text-2xl sm:text-5xl font-bold w-full text-slate-700 text-center">
      Nossos serviços
    </h2>
    <p class="text-lg xl:w-1/2 sm:text-xl text-slate-500 sm:text-center mt-4 sm:mt-8">
      Da consultoria ao desenvolvimento de software, a Navigandi está comprometida em atender suas
      necessidades específicas no controle e monitoramento de embarcações
    </p>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 w-full sm:p-4 mt-6 sm:mt-24">
      <div>
        <div class="flex flex-row items-center">
          <i class="fa-solid fa-mobile-screen-button text-slate-500 text-2xl"></i>
          <h3 class="text-xl w-full text-slate-700 text-left ml-2">
            <span class="border-b-2 border-yellow-300 pb-1">Software</span> Customizado
          </h3>
        </div>
        <p class="text-base text-slate-500 text-left mt-4">
          Desenvolvemos programas de navegação e monitoramento sob medida para suas necessidades.
        </p>
      </div>
      <div>
        <div class="flex flex-row items-center">
          <i class="fa-solid fa-water text-slate-500 text-2xl"></i>
          <h3 class="text-xl w-full text-slate-700 text-left ml-2">
            <span class="border-b-2 border-yellow-300 pb-1">Dados</span> Hidrográficos
          </h3>
        </div>
        <p class="text-base text-slate-500 text-left mt-2">
          Tratamento e visualização de informações hidrográficas para softwares de navegação e
          monitoramento.
        </p>
      </div>
      <div>
        <div class="flex flex-row items-center">
          <i class="fa-solid fa-gears text-slate-500 text-2xl"></i>
          <h3 class="text-xl w-full text-slate-700 text-left ml-2">
            <span class="border-b-2 border-yellow-300 pb-1">Aferição</span> de Embarcações
          </h3>
        </div>
        <p class="text-base text-slate-500 text-left mt-2">
          Serviços de medição precisa de movimentos para análise de risco, modelagem e simulação.
        </p>
      </div>

      <div>
        <div class="flex flex-row items-center">
          <i class="fa-solid fa-gauge text-slate-500 text-2xl"></i>
          <h3 class="text-xl w-full text-slate-700 text-left ml-2">
            <span class="border-b-2 border-yellow-300 pb-1">Projetos</span> de Instrumentação
          </h3>
        </div>
        <p class="text-base text-slate-500 text-left mt-2">
          Soluções em instrumentação e controle para projetos especiais.
        </p>
      </div>

      <div>
        <div class="flex flex-row items-center">
          <i class="fa-solid fa-location-arrow text-slate-500 text-2xl"></i>
          <h3 class="text-xl w-full text-slate-700 text-left ml-2">
            <span class="border-b-2 border-yellow-300 pb-1">Sistemas</span> de Navegação
          </h3>
        </div>
        <p class="text-base text-slate-500 text-left mt-2">
          Equipamentos e sistemas especializados para navegação e acompanhamento de operações.
        </p>
      </div>

      <div>
        <div class="flex flex-row items-center">
          <i class="fa-solid fa-chalkboard-user text-slate-500 text-2xl"></i>
          <h3 class="text-xl w-full text-slate-700 text-left ml-2">
            <span class="border-b-2 border-yellow-300 pb-1">Consultoria</span> e Treinamento
          </h3>
        </div>
        <p class="text-base text-slate-500 text-left mt-2">
          Oferecemos consultoria especializada e treinamento em todas as nossas áreas de atuação.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="w-full" id="products">
  <section
    class="flex flex-col items-center justify-between w-full sm:h-screen py-6 sm:py-24 bg-[url('/assets/signin-background.jpg')] bg-cover bg-center bg-no-repeat bg-blue-200 bg-blend-multiply"
    id=" ORBIS"
  >
    <div
      class="flex flex-col justify-center items-center w-full xl:w-1/2 sm:max-w-7xl mx-auto text-3xl p-4"
    >
      <h2 class="text-2xl sm:text-5xl font-bold w-full text-white text-center">Produtos</h2>
      <p class="text-lg sm:text-xl w-full text-slate-100 text-center mt-8">
        A Navigandi oferece uma variedade de soluções para tornar suas operações de navegação mais
        seguras e eficientes.
      </p>
    </div>

    <div class="flex flex-col justify-center items-center w-full h-full">
      <div
        class="flex flex-col sm:flex-row justify-start sm:justify-end items-center w-full sm:max-w-7xl mx-auto text-3xl p-4 sm:pt-4 h-full"
      >
        <div
          class="flex flex-col justify-center items-start w-full sm:w-1/2 p-8 sm:mr-4 bg-slate-50 rounded shadow"
        >
          <h2 class="text-xl font-medium sm:text-4xl w-full text-slate-900 text-left">ORBIS®</h2>
          <p class="text-lg sm:text-base text-slate-900 text-left sm:mt-4">
            O PPU mais leve e preciso da categoria
          </p>
          <p class="text-lg sm:text-base text-slate-500 text-left sm:mt-4">
            O Navigandi Orbis redefine os padrões de PPU com sua precisão inigualável e design
            ergonômico. Desenvolvido e testado em colaboração com a praticagem brasileira, o Orbis é
            a escolha confiável para manobras seguras e eficientes.
          </p>
          <button
            (click)="openProductInfoDialog()"
            class="flex flex-row items-center bg-yellow-500 hover:bg-yellow-700 text-white text-base font-bold py-2 px-4 rounded-full shadow mt-4"
          >
            <i class="fa-solid fa-info mr-2"></i>
            Saiba mais
          </button>
        </div>
      </div>
    </div>
  </section>

  <section
    class="flex flex-col items-center justify-between w-full sm:h-screen py-6 sm:py-24 bg-slate-50"
  >
    <div
      class="flex flex-col justify-center items-center w-full xl:w-1/2 sm:max-w-7xl mx-auto text-3xl p-4"
    >
      <h2 class="text-2xl sm:text-5xl font-bold w-full text-slate-700 text-center">
        Outros produtos
      </h2>
      <p class="text-lg sm:text-xl w-full text-slate-500 text-center mt-8">
        A Navigandi oferece uma variedade de soluções para tornar suas operações de navegação mais
        seguras e eficientes.
      </p>
    </div>
    <div
      class="flex flex-col sm:flex-row justify-center items-center w-full sm:max-w-7xl mx-auto text-3xl p-4 h-full"
    >
      <div
        class="flex flex-col justify-start max-w-sm min-h-96 rounded overflow-hidden shadow-lg mx-4 bg-white"
      >
        <div class="w-full">
          <img class="w-full object-fill" src="assets/navicombo.png" alt="NaviCombo" />
        </div>
        <div class="px-6 py-4 sm:min-h-36 flex-grow">
          <div class="font-bold text-xl mb-2">NaviCombo</div>
          <p class="text-slate-700 text-base">Sistema de Navegação para Comboios.</p>
        </div>
      </div>
      <div
        class="flex flex-col justify-start max-w-sm min-h-96 rounded overflow-hidden shadow-lg mx-4 mt-4 sm:mt-0 bg-white"
      >
        <div class="w-full">
          <img class="w-full object-fill" src="assets/navipilot.png" alt="NavigandiPilot" />
        </div>
        <div class="px-6 py-4 sm:min-h-36 flex-grow">
          <div class="font-bold text-xl mb-2">Navigandi Pilot</div>
          <p class="text-slate-700 text-base">
            Consciência situacional aumentada para manobras mais seguras e eficazes.
          </p>
        </div>
      </div>

      <div
        class="flex flex-col justify-start max-w-sm min-h-96 rounded overflow-hidden shadow-lg mx-4 mt-4 sm:mt-0 bg-white"
      >
        <div class="w-full">
          <img class="w-full object-fill" src="assets/soluções.jpg" alt="NaviCombo" />
        </div>
        <div class="px-6 py-4 sm:min-h-36 flex-grow">
          <div class="font-bold text-xl mb-2">Soluções únicas</div>
          <p class="text-slate-700 text-base">
            Serviços personalizados para atender às suas necessidades específicas.
          </p>
        </div>
      </div>
    </div>
  </section>
</section>

<section class="flex flex-col items-center justify-between w-full py-6 sm:py-24 bg-white">
  <div class="flex flex-col justify-center items-center w-full sm:max-w-7xl mx-auto text-3xl p-4">
    <div
      class="flex flex-col justify-center items-center w-full xl:w-1/2 sm:max-w-7xl mx-auto text-3xl p-4"
    >
      <h2 class="text-2xl sm:text-5xl font-bold w-full text-slate-700 text-center">
        Colaborações de Sucesso
      </h2>
    </div>

    <div class="flex w-full justify-center items-center mt-6 sm:mt-24">
      <gallery
        [items]="imagesCarrousel"
        [loop]="true"
        [thumbs]="false"
        [visibleItems]="5"
        [counter]="false"
        [objectFit]="'contain'"
        class="w-full h-20 rounded-2xl"
      >
      </gallery>
    </div>
  </div>
</section>

<section class="w-full bg-blue-900 py-6 sm:py-24" id="contact">
  <div
    class="flex flex-col sm:flex-row justify-center items-center w-full sm:max-w-7xl mx-auto text-3xl p-4 sm:pt-4 h-full"
  >
    <div class="flex flex-col justify-center items-center w-full">
      <h2 class="text-2xl sm:text-5xl font-bold text-white sm:max-w-7xl mx-auto text-center">
        Solicite uma demonstração
      </h2>
      <p class="text-lg sm:text-xl text-slate-300 text-center mt-2 sm:mt-4">
        Nossos especialistas irão lhe apresentar os benefícios e vantagens dos produtos Navigandi
      </p>
      <a
        href="mailto:contato@navigandi.com.br"
        class="bg-blue-500 hover:bg-blue-600 text-white text-lg font-bold py-4 px-8 rounded mt-4 sm:mt-8"
        >Tenho interesse</a
      >
    </div>
  </div>
</section>

<footer class="fw-full bg-blue-950 py-8">
  <div class="flex flex-col items-center w-full sm:max-w-7xl mx-auto text-3xl p-4 sm:pt-4 h-full">
    <div class="flex flex-col justify-center items-center w-full">
      <img src="assets/navigandi-logo-white.png" alt="Logo da empresa" class="w-32 h-auto" />
      <span class="text-base text-slate-200">Tecnologia 100% nacional para mares globais.</span>
    </div>

    <div class="flex flex-row items-center justify-center">
      <div class="flex flex-row justify-center items-baseline w-full mt-4">
        <span class="flex text-sm text-slate-400"
          >Rua Cerro Corá, 1306 Cj 18 São Paulo, SP, 05061-000, Brasil</span
        >
        <a href="https://www.linkedin.com/company/naviganditech/" target="_blank">
          <i class="fa-brands fa-linkedin-in text-slate-400 text-2xl ml-4 mr-4"></i>
        </a>
        <a href="https://www.instagram.com/navigandi_tech/" target="_blank">
          <i class="fa-brands fa-instagram text-slate-400 text-2xl mr-4"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center items-center w-full">
    <p class="text-sm text-slate-400 text-center mt-4">
      © 2025 Navigandi Pesquisas e Desenvolvimento de Equipamentos Marítimos Ltda - CNPJ:
      33.448.603/0001-00
    </p>
    <p class="text-sm text-slate-400 text-center mt-4">Todos os direitos reservados</p>
  </div>
</footer>
