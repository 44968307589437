import { catchError, Observable, switchMap } from 'rxjs';

import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { AuthService } from '../../modules/auth/auth.service';
import { SnackBarService } from '../../shared/components/snack-bar/snack-bar.service';
import { TokenService } from '../services/token.service';

export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const tokenService = inject(TokenService);
  const authService = inject(AuthService);
  const snackbarService = inject(SnackBarService);
  const accessToken = tokenService.getAccessToken();

  if (accessToken !== '' && !tokenService.isTokenExpired()) {
    const newReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
    });
    return next(newReq);
  }
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      console.log(error);
      if (error.status === 401 || error.status === 500) {
        snackbarService
          .showSnackbar('Token expirado!', 'Faça login novamente.', 3000)
          .afterDismissed()
          .subscribe(() => {
            authService.logout();
          });
      }
      throw error; // propagate the error further
    }),
  );
}
