import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { GalleryComponent, GalleryItem } from '@daelmaak/ngx-gallery';

import { ProductInfoDialogComponent } from '../components/product-info-dialog/product-info-dialog.component';

@Component({
  selector: 'app-landing-page',
  imports: [NgIf, MatIconModule, GalleryComponent],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
})
export class LandingPageComponent {
  isCollapsed = false;
  isMobile = true;

  imagesCarrousel: GalleryItem[] = [
    { src: 'assets/logo_TPN-300x161.png', thumbSrc: 'assets/logo_TPN-300x161.png' },
    { src: 'assets/logo-barra-do-para.png', thumbSrc: 'assets/logo-barra-do-para.png' },
    { src: 'assets/Logo-Espirito-Santo.png', thumbSrc: 'assets/Logo-Espirito-Santo.png' },
    { src: 'assets/Logo-Itajai.png', thumbSrc: 'assets/Logo-Itajai.png' },
    { src: 'assets/Logo-Paranagua.png', thumbSrc: 'assets/Logo-Paranagua.png' },
    { src: 'assets/logo-pernambuco-pilots.png', thumbSrc: 'assets/logo-pernambuco-pilots.png' },
    { src: 'assets/Logo-RJ.png', thumbSrc: 'assets/Logo-RJ.png' },
    { src: 'assets/Logo-Santos-Pilots.png', thumbSrc: 'assets/Logo-Santos-Pilots.png' },
    { src: 'assets/Logo-Sao-Francisco.png', thumbSrc: 'assets/Logo-Sao-Francisco.png' },
    { src: 'assets/logo-unipilot.png', thumbSrc: 'assets/logo-unipilot.png' },
  ];

  constructor(private dialog: MatDialog) {}

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
    console.log(this.isCollapsed);
  }

  openProductInfoDialog() {
    this.dialog.open(ProductInfoDialogComponent, {
      maxWidth: '90vw',
      width: '100%',
    });
  }
}
