<h2 mat-dialog-title>ORBIS®</h2>
<mat-dialog-content class="mat-typography">
  <div class="flex flex-col sm:flex-row items-start">
    <div class="flex flex-col sm:flex-col items-center h-full w-full sm:w-1/3">
      <gallery [items]="images" class="w-full h-auto rounded-2xl"></gallery>
      <div class="flex flex-col sm:flex-col items-center justify-start w-full">
        <a
          href="assets/orbis_brochure.pdf"
          target="_blank"
          class="flex flex-row items-center w-full text-slate-500 text-base font-bold mt-4 border-2 px-4 py-2 sm:mr-1 border-slate-300"
        >
          <i class="fa-solid fa-file-pdf text-xl mr-2"></i>
          Especificações técnicas
        </a>
        <a
          href="assets/manual-Orbis_rev05.pdf"
          target="_blank"
          class="flex flex-row items-center w-full text-slate-500 text-base font-bold mt-4 border-2 px-4 py-2 sm:ml-1 border-slate-300"
        >
          <i class="fa-solid fa-file-pdf text-xl mr-2"></i>
          Manual do usuário
        </a>
      </div>
    </div>
    <div
      class="flex flex-col items-center justify-start h-full mt-4 sm:mt-0 sm:px-4 w-full sm:w-2/3"
    >
      <h2 class="text-lg sm:text-lg w-full text-slate-900 text-left">
        O PPU mais leve e preciso da categoria
      </h2>
      <p class="text-lg sm:text-base w-full text-slate-500 text-left sm:mt-2">
        O Navigandi Orbis redefine os padrões de PPU com sua precisão inigualável e design
        ergonômico. Desenvolvido e testado em colaboração com a praticagem brasileira, o Orbis é a
        escolha confiável para manobras seguras e eficientes.
      </p>
      <p class="text-lg sm:text-base w-full text-slate-500 text-left mt-2">
        O Navigandi Orbis® foi projetado e construído para atingir as demandas mais desafiadoras do
        mercado da praticagem, com o objetivo de se tornar o principal companheiro do Prático.
        Combinando sensores altamente precisos e as mais avançadas técnicas de processamento de
        sinais, ele é capaz de prover medidas confiáveis e responsivas, enquanto mantém as vantagens
        ergonômicas de um tamanho reduzido e baixo peso.
      </p>

      <div class="flex flex-col items-start w-full">
        <ul class="ml-8 text-lg sm:text-base text-slate-500 text-left mt-2">
          <li class="list-disc">Estado da arte em algoritmos de fusão sensorial e estimação</li>
          <li class="list-disc">Suporte a múltiplas frequências e constelações</li>
          <li class="list-disc">Resistência a spoong e jamming</li>
          <li class="list-disc">Precisão superior em relação aos instrumentos presentes a bordo</li>
          <li class="list-disc">Testado e aprovado por diversas praticagens brasileiras</li>
        </ul>
      </div>

      <div class="flex flex-col items-start w-full mt-4">
        <div class="flex flex-row items-center">
          <h3 class="text-xl w-full font-bold text-slate-700 text-left">
            <span class="border-b-2 border-yellow-400 pb-1">Preciso</span> e Confiável
          </h3>
        </div>
        <p class="text-base text-slate-500 text-left mt-2">
          Equipado com algoritmos de última geração e aprovado por praticagens em todo o Brasil.
        </p>

        <div class="flex flex-row items-center">
          <h3 class="text-xl w-full font-bold text-slate-700 text-left">
            <span class="border-b-2 border-yellow-500 pb-1">Leve</span> e Ergonômico
          </h3>
        </div>
        <p class="text-base text-slate-500 text-left mt-2">
          Design compacto com um peso de apenas 600g por antena.
        </p>

        <div class="flex flex-row items-center">
          <h3 class="text-xl w-full font-bold text-slate-700 text-left">
            <span class="border-b-2 border-yellow-400 pb-1">Instalação</span> Rápida
          </h3>
        </div>
        <p class="text-base text-slate-500 text-left mt-2">
          Equipamento totalmente operacional em menos de 2,5 minutos.
        </p>

        <div class="flex flex-row items-center">
          <h3 class="text-xl w-full font-bold text-slate-700 text-left">
            <span class="border-b-2 border-yellow-400 pb-1">Excelência</span> em Suporte
          </h3>
        </div>
        <p class="text-base text-slate-500 text-left mt-2">
          Oferecemos Hardware-as-Service e a manutenção mais rápida do segmento.
        </p>
      </div>
    </div>
  </div>
  <div class="flex flex-col sm:flex-row items-start mt-8"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-dialog-close
    type="button"
    class="hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 rounded"
  >
    Fechar
  </button>
</mat-dialog-actions>
